import { useState, useEffect } from 'react';
import axios from 'axios';

const DASHBOARD_API_URL = process.env.REACT_APP_API

const useSpreads = () => {
  const [spreadsResult, setSpreadsResult] = useState();

  useEffect(() => {
    const getSpreads = async () => {
      try {
        const response = await axios.get(`${DASHBOARD_API_URL}/rates-fees?populate=*`);

        const json = response.data;

        const feeResult = json.data.map((item) => ({
          country: item.attributes.country.data.attributes.name.toLowerCase(),
          currencyCode: `${item.attributes.country.data.attributes.currencyCode}`,
          rateFee: item.attributes.rateFee,
        }));

        setSpreadsResult(feeResult)

      } catch (error) {
        console.error(error);
      }
    }

    getSpreads();
  }, []);

  return { spreadsResult };
};

export default useSpreads;
