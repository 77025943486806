import React , {useEffect} from "react";
import {Helmet} from "react-helmet";
import { Routes, Route } from "react-router-dom";
import Aos from 'aos'

import Home from './pages/Home'

import 'aos/dist/aos.css';
import './assets/css/bootstrap.min.css';
import './assets/css/General.css';
import './assets/css/responsive.css';
// import './assets/css/dzsparallaxer.css'

import 'bootstrap/dist/js/bootstrap.bundle.min';
import RatesPage from './pages/Rates';

const App = () => {

  useEffect(() => {

    Aos.init({
      duration: 1000
      })
  },[])

  return (

    	<div className="App">
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
          <meta name="keywords" content="zetlon, exchange, crypto, payment"/>
          <title>
            Zetlon Exchange
          </title>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet"/>
          <link rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0=" crossorigin="anonymous"/>
        </Helmet>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/rates" element={<RatesPage/>} />
        </Routes>
	    </div>
  );
}

export default App;
